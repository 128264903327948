import * as React from "react"
import CaseStudyTemplate from "../components/case-study-template";
import { StaticImage } from "gatsby-plugin-image";

const content = {
	title: `Somersby Cider`,
	heading: 'Shake & Win Promotion.',
	emphasisedText: 'A custom promotion with multi-tiered instant wins and entry gamification',
	text: [
		'Coinciding with the launch of their revised brand identity, Somersby Cider engaged KO via Wunderman Thompson to develop a consumer promotion with multiple tiers of instant win prizes and an interactive element for consumers to engage with in order to display the result of their entry.',
		'Developed with a mobile first design in mind, the aim of the interaction, which became the name of the promotion, was to shake and win. Users were directed to touch and drag the tree, which would shake on release, as well as triggering confetti, aeroplanes and European landmarks as an exciting display of the instant win result.'
	],
	numbers: {
		Entrants: '3000',
		Winners: '500',
		'In prizes': '$1M',
	},
};

export default function Somersby() {
	return (
		<CaseStudyTemplate 
			heading={content.heading}
			superHeading={content.title}
			image={<StaticImage alt="Hero image" src="../images/Somersby@3x.png" width={500} height={500} />}
			content={{
				title: content.emphasisedText,
				text: content.text,
			}}
			numbers={content.numbers}
		/>
	)
}
